import axios from 'axios'

export const fetchCards = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/cards`
    )
    // console.log('response cards', response)
    const cardsData = response && response.data.data.cards
    const reversedCardsData = cardsData.reverse()
    // console.log(
    //   'In fetchData response.data.data  data-type:',
    //   typeof cardsData,
    //   cardsData
    // )
    // return 'working'
    return reversedCardsData
    // return cardsData
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const fetchEnquiry = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/enquiry`
    )
    // console.log('response enquiry', response)
    const enquiryData = response && response.data.data.enquiry
    const reversedEnquiryData = enquiryData.reverse()
    return reversedEnquiryData
    // return enquiryData
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const fetchDeleteCard = async (id) => {
  try {
    const response = await axios.delete(
      // `https://test.saasjournal.io/service/api/cards/${id}`
      `${process.env.REACT_APP_BASE_URL}/api/cards/${id}`
    )
    // console.log('response after delete', response)
    const cardsData = response && response.data
    // const reversedCardsData = cardsData.reverse()
    // console.log('response after deletion: ', response.data.message)
    // if (response.data.message === 'Card deleted') {
    return cardsData
    // }
  } catch (error) {
    console.log('Error deletion', error)
    throw error
  }
}
export const fetchUpdateCard = async ({ inputId, formData }) => {
  // console.log('In the fetchUpdateCard, inputId', inputId)
  // console.log('In the fetchUpdateCard, editedValues', formData)
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/cards/${inputId}`,
      formData
    )
    // console.log('response  fetchUpdateCard after update', response)
    const cardsData = response && response.data.data
    const reversedCardsData = cardsData.reverse()
    // console.log('response after update: ', response.data.message)
    if (response.data.message === 'Card updated') {
      return reversedCardsData
    }
  } catch (error) {
    console.log('Error Updation', error)
    throw error
  }
}
export const fetchGetCard = async (id) => {
  // console.log('In the fetchCard, id value:', id)
  try {
    // console.log('In the fetchCard try, id value:', id)
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/cards/${id}`
    )
    // console.log(
    //   'response after get card, response.data.data',
    //   response.data.data
    // )
    const cardsData = response && response.data.data
    // const reversedCardsData = cardsData.reverse()
    // console.log(
    //   'response after update,response.data.message: ',
    //   response.data.message
    // )
    if (response.data.message === 'Card found') {
      return cardsData
    }
  } catch (error) {
    console.log('Error fetching card', error)
    throw error
  }
}

export const fetchDeleteEnquiry = async (id) => {
  // console.log('equiry staged for deletion', id)
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/enquiry/${id}`
    )
    // console.log('response after delete', response)
    const enquiryData = response && response.data
    // const reversedEnquiryData = enquiryData.reverse()
    // console.log('service response after deleting enq: ', response.data)
    if (response.data.message === 'Enquiry deleted') {
      return enquiryData
    }
  } catch (error) {
    console.log('Error deletion', error)
    throw error
  }
}

export const formLogin = async (email, password) => {
  try {
    // console.log('api call', `${process.env.REACT_APP_BASE_URL}/api/users/login`)
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/users/login`,
      {
        email,
        password,
      }
    )
    return response
  } catch (err) {
    console.log(err)
    return err
  }
}

export const fetchAuthors = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/authors`
    )
    // console.log('response of the authors', response)
    const authorsData = response && response.data.data.authors

    return authorsData
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const fetchAuthor = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/authors/${id}`
    )
    // console.log('response of the author fetched', response)
    const authorData = response && response.data.data
    return authorData
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const fetchDeleteAuthor = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/authors/${id}`
    )
    // console.log('response after delete', response.data)
    const authorsData = response && response.data
    return authorsData
  } catch (error) {
    console.log('Error deletion', error)
    throw error
  }
}
export const fetchUpdateAuthor = async (id) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/authors/${id}`
    )
    // console.log('response of the author fetched', response)
    const authorData = response && response.data.data
    return authorData
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const fetchTrasnsferAuthorArticles = async (
  oldAuthorId,
  newAuthorId
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/api/authors/transferAuthorArticles`,
      { oldAuthorId, newAuthorId }
    )
    // console.log('response of the author transfer', response.data)
    const authorData = response && response.data
    return authorData
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const fetchCategory = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/category`
    )
    // console.log('response of the category fetched', response)
    const categoryData = response && response.data.data.category
    return categoryData
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const fetchCreateCategory = async (formData) => {
  // console.log('Fetching category, catergory name:', category)
  // try

  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/category`,
    formData
  )
  // console.log('response of the category create', response.data)
  const categoryData = response && response.data.message
  return categoryData

  // catch (error) {
  //   console.log('Error creating category:', error.response.data.message)
  // }
}
export const fetchUpdateCategory = async (id, category) => {
  // console.log('Fetching category, id:', id)
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/category/${id}`,
      { name: category }
    )
    // console.log('response of the category update', response.data)
    const categoryData = response && response.data
    return categoryData
  } catch (error) {
    console.log('Error updating category:', error.response.data.message)
  }
}

export const fetchDeleteCategory = async (id) => {
  // console.log('Fetching category, id:', id)
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/category/${id}`
    )
    // console.log('response of the category delete', response.data)
    const categoryData = response && response.data
    return categoryData
  } catch (error) {
    console.log('Error deleting category:', error.response.data.message)
  }
}

export const fetchCardClick = async (id) => {
  // console.log('Card clicked, id:', id)
  try {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/api/cards/${id}/click`)
    // console.log('Click count updated', response.data.data)
  } catch (error) {
    console.log('Error updating card click count:', error.response)
  }
}
export const fetchCardClickByName = async (headertext) => {
  // console.log('Card clicked, headertext:', headertext)
  try {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/cards/posts/${headertext}`
    )
    // console.log('Click count updated', response.data.data)
  } catch (error) {
    console.log('Error updating card click count:', error.response)
  }
}

export const fetchEnquirySubmit = async (enquiry) => {
  // console.log('Fetching enquiry, enquiry details:', enquiry)
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/enquiry`,
      enquiry
    )
    // console.log('response of the enquiry submit', response.data)
    const enquiryData = response && response.data.message
    return enquiryData
  } catch (error) {
    console.log('Error submitting enquiry:', error.response.data.message)
  }
}

export const fetchEmailSubscription = async (input) => {
  console.log('Fetching email subscription, email details:', input)
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/subscriptions/subscribe`,
      { input }
    )
    const emailResponse = response && response.data.message
    return emailResponse
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message)
    }
    throw new Error('Failed to subscribe email')
  }
}

export const fetchCreateMetadescription = async (name, metadescription) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/metadescriptions`,
      { name, metadescription }
    )
    // console.log('response of the meta description create', response.data)
    const metaDescriptionData = response && response.data.data
    return metaDescriptionData
  } catch (error) {
    console.log('Error creating meta description:', error.response.data.message)
    throw error
  }
}

export const fetchGetAllMetadescription = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/metadescriptions`
    )
    // console.log(
    //   'response of the meta description fetched',
    //   response.data.data.metaDescriptions
    // )
    const metaDescriptionData = response && response.data.data.metaDescriptions
    return metaDescriptionData
  } catch (error) {
    console.error('Error fetching meta description data:', error.response)
    throw error
  }
}

export const fetchGetMetaDescription = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/metadescriptions/${id}`
    )
    // console.log('response of the meta description fetched', response.data.data)
    const metaDescriptionData = response && response.data.data
    return metaDescriptionData
  } catch (error) {
    console.error('Error fetching meta description data:', error.response)
    throw error
  }
}

export const fetchUpdateMetaDescription = async (id, updateData) => {
  // console.log('updating meta description data', id, updateData)
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/metadescriptions/${id}`,
      updateData
    )
    // console.log('response of the meta description update', response.data)
    const metaDescriptionData = response && response.data
    return metaDescriptionData
  } catch (error) {
    console.log('Error updating meta description:', error.response.data.message)
    throw error
  }
}

export const fetchDeleteMetadescription = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/metadescriptions/${id}`
    )
    // console.log('response of the meta description delete', response.data)
    const metaDescriptionData = response && response.data.message
    return metaDescriptionData
  } catch (error) {
    console.log('Error deleting meta description:', error.response.data.message)
    throw error
  }
}

export const getVisitorsCount = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/total-visitors`
    )
    // console.log('response of visitors count', response.data.totalVisitors)
    return response.data.totalVisitors
  } catch (error) {
    console.error('Error fetching visitors count:', error.response)
    throw error
  }
}
