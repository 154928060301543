import React, { Suspense } from 'react'
import { CircularProgress, Paper } from '@mui/material'
import { Routes, Route } from 'react-router-dom'
import '../src/App.css'
import { DataProvider } from './Components/DataContext/DataContext'
import useScrollToTop from './utils/UseScrollTop'
import TermsOfService from './Components/Footer/TermsOfService'

// Lazy load components
const Home = React.lazy(() => import('./Components/HomeCompCont/Home'))
const AboutUs = React.lazy(() => import('./Components/AboutUsCont/AboutUs'))
const AdvertiseWdUs = React.lazy(() =>
  import('./Components/AdvertiseWdUsCont/AdvertiseWdUs')
)
const ContactUs = React.lazy(() => import('./Components/ContactUs/ContanctUs'))
const Footer = React.lazy(() => import('./Components/Footer/Footer'))
const NavDrawer2 = React.lazy(() => import('./NavDrawer/NavDrawer2'))
const LogIn = React.lazy(() => import('./Components/Admin/LogIn'))
const AdminPanelV2 = React.lazy(() => import('./Components/Admin/AdminPanelV2'))
const ArticleCreate = React.lazy(() =>
  import('./Components/Admin/Table/Article/ArticleCreate')
)
const AuthorSpecificContent = React.lazy(() =>
  import('./Components/AboutUsCont/AuthorSpecificContent')
)
const PageBuilder = React.lazy(() =>
  import('./Components/PageBuilder/PageBuilder')
)
const CategoryWiseArticle = React.lazy(() =>
  import('./Components/PageBuilder/CategoryWiseArticle')
)
const AddAuthor = React.lazy(() =>
  import('./Components/Admin/Table/Author/AddAuthor')
)
const PrivacyPolicy = React.lazy(() => import('./utils/PrivacyPolicy'))
const Metadescriptions = React.lazy(() =>
  import('./Components/Admin/Table/Metadescription/Metadescriptions')
)
// const Categories = React.lazy(() => import('./Components/Admin/Table/Category/Categories'));
const Blogs = React.lazy(() => import('./Components/Blogs/Blogs'))

// Navigation index
const navIndexArray = [
  { text: 'Home', route: '/' },
  { text: 'About Us', route: '/about-us' },
  { text: 'Blogs', route: '/blogs' },
  { text: 'Contact Us', route: '/contact-us' },
  { text: 'Advertise With Us', route: '/advertise-with-us' },
]

function App() {
  useScrollToTop()

  return (
    <DataProvider>
      <Paper elevation={0}>
        <Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'white', // Optional: to make sure the background is white
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <NavDrawer2 links={navIndexArray} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/advertise-with-us" element={<AdvertiseWdUs />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/admin-panel" element={<AdminPanelV2 />} />
            <Route path="/card-create" element={<ArticleCreate />} />
            <Route path="/author-create" element={<AddAuthor />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/metadesc-create" element={<Metadescriptions />} />
            <Route
              path="/author-content/:authorName"
              element={<AuthorSpecificContent />}
            />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:slug" element={<PageBuilder />} />
            <Route
              path="/category/:categoryName"
              element={<CategoryWiseArticle />}
            />
          </Routes>
          <Footer />
        </Suspense>
      </Paper>
    </DataProvider>
  )
}

export default App
