import { createContext, useContext, useEffect, useState } from 'react'
import {
  fetchCards,
  fetchEnquiry,
  fetchDeleteCard,
  fetchDeleteEnquiry,
  formLogin,
  fetchUpdateCard,
  fetchGetCard,
  fetchAuthors,
  fetchCategory,
  fetchCreateCategory,
  fetchGetAllMetadescription,
  fetchAuthor,
} from './DataService'
import { useNavigate } from 'react-router-dom'
import { Alert } from '@mui/material'

const DataContext = createContext()

const initialValues = {
  email: '',
  password: '',
}

const AdminCurrentCreation = [
  'Dashboard',
  'Metadescriptions',
  'Categories',
  'Authors',
  'Create Article',
  'View Articles',
  'Enquiries',
]

export const useData = () => useContext(DataContext)

export const DataProvider = ({ children }) => {
  const [cards, setCards] = useState([])
  const [cardEditState, setCardEditState] = useState(false)
  const [cardEditValue, setCardEditValue] = useState(null)
  const [authorEditValue, setAuthorEditValue] = useState(null)
  const [authorEditState, setAuthorEditState] = useState(false)
  const [enquiry, setEnquiry] = useState([])
  const [cardDeleteState, setCardDeleteState] = useState(false)
  const [enquiryDeleteState, setEnquiryDeleteState] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [logged, setLoggedIn] = useState({
    state: false,
    value: 'Login',
    token: null,
  })
  const [loginError, setLoginError] = useState(null)
  const [input, setInputs] = useState(initialValues)
  const [enquiryTable, setEnquiryTable] = useState(false)
  const [selectedTab, setSelectedTab] = useState(AdminCurrentCreation[0])
  const [cardTable, setCardTable] = useState(false)
  const [cardCreated, setCardCreated] = useState(false)
  const [authors, setAuthors] = useState([])
  const [authorSelected, setAuthorSelected] = useState('')
  const [categories, setCategories] = useState([])
  const [metaDescription, setMetaDescription] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    // Load token from localStorage or sessionStorage on component mount
    const localStorageToken = localStorage.getItem('token')
    const sessionStorageToken = sessionStorage.getItem('token')

    if (sessionStorageToken) {
      setLoggedIn({ state: true, value: 'Logout', token: sessionStorageToken })
    } else if (localStorageToken) {
      sessionStorage.setItem('token', localStorageToken)
      setLoggedIn({ state: true, value: 'Logout', token: localStorageToken })
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          cardsResult,
          enquiryResult,
          authorResults,
          categoryResult,
          metadescriptionResults,
        ] = await Promise.all([
          fetchCards(),
          fetchEnquiry(),
          fetchAuthors(),
          fetchCategory(),
          fetchGetAllMetadescription(),
        ])

        setCards(cardsResult)
        setEnquiry(enquiryResult)
        setAuthors(authorResults)
        setCategories(categoryResult)
        setMetaDescription(metadescriptionResults)
        setIsLoading(false)
      } catch (error) {
        console.error('Fetching error in DataContext:', error)
      }
    }

    fetchData()
  }, [])

  const handleTabClick = (tab) => {
    if (authorEditState || cardEditState) {
      const userConfirmed = window.confirm(
        'You have unsaved changes. Do you want to discard them and proceed?'
      )

      if (userConfirmed) {
        // If the user clicks "OK", reset the edit states
        if (authorEditState) {
          setAuthorEditState(false)
        } else if (cardEditState) {
          setCardEditState(false)
        }

        // Then set the selected tab
        setSelectedTab(tab)
      } else {
        // If the user clicks "Cancel", do nothing and return
        return
      }
    } else {
      // No unsaved changes, so proceed with setting the tab
      setSelectedTab(tab)
    }
  }

  const categoryCreate = async (category) => {
    try {
      const createResult = await fetchCreateCategory(category)
      return createResult
    } catch (error) {
      console.error('Error creating category:', error)
    }
  }

  const cardDelete = async (id) => {
    try {
      const deleteResult = await fetchDeleteCard(id)
      return deleteResult
    } catch (error) {
      console.error('Error deleting card:', error)
    }
  }

  const cardUpdate = async ({ inputId, formData }) => {
    try {
      const updateResult = await fetchUpdateCard({ inputId, formData })
      setCards(updateResult)
      return updateResult
    } catch (error) {
      console.error('Error updating card:', error)
    }
  }

  const cardGet = async (id) => {
    try {
      const getResult = await fetchGetCard(id)
      setCardEditValue(getResult.cardResponse)
      setCardEditState(true)
    } catch (error) {
      console.error('Error getting card:', error)
    }
  }

  const authorGet = async (id) => {
    try {
      const getResult = await fetchAuthor(id)
      setAuthorEditValue(getResult.author)
      setAuthorEditState(true)
    } catch (error) {
      console.error('Error getting author:', error)
    }
  }

  const enquiryDelete = async (id) => {
    try {
      const deleteResult = await fetchDeleteEnquiry(id)
      return deleteResult
    } catch (error) {
      console.error('Error deleting enquiry:', error)
    }
  }

  const loginFromSubmitHandler = async (email, password) => {
    try {
      const loginResult = await formLogin(email, password)
      if (loginResult.status === 200) {
        const token = loginResult.data.token
        localStorage.setItem('token', token)
        sessionStorage.setItem('token', token)
        setLoggedIn({ state: true, value: 'Logout', token })
        return loginResult
      }
    } catch (err) {
      console.error('Login error:', err)
      setLoginError(err.response?.data?.message || 'Wrong credentials.')
    }
  }

  const logOutHandler = () => {
    if (logged.state) {
      localStorage.removeItem('token')
      sessionStorage.removeItem('token')
      setLoggedIn({ state: false, value: 'Login', token: null })
    } else {
      navigate('/login')
    }
  }

  const logInHandler = () => {
    if (!logged.state) {
      navigate('/login')
    } else {
      return <Alert>Already Logged in</Alert>
    }
  }

  return (
    <DataContext.Provider
      value={{
        AdminCurrentCreation,
        fetchCategory,
        categoryCreate,
        categories,
        setCategories,
        authors,
        setAuthors,
        authorGet,
        authorEditValue,
        authorEditState,
        setAuthorEditState,
        authorSelected,
        setAuthorSelected,
        setCards,
        cards,
        setCardDeleteState,
        cardDeleteState,
        cardDelete,
        cardUpdate,
        cardEditValue,
        cardEditState,
        setCardEditState,
        setCardEditValue,
        cardCreated,
        setCardCreated,
        cardGet,
        enquiry,
        setEnquiry,
        enquiryDelete,
        enquiryDeleteState,
        setEnquiryDeleteState,
        isLoading,
        loginFromSubmitHandler,
        logged,
        setLoggedIn,
        logInHandler,
        loginError,
        logOutHandler,
        input,
        setInputs,
        handleTabClick,
        cardTable,
        setCardTable,
        selectedTab,
        setSelectedTab,
        enquiryTable,
        setEnquiryTable,
        metaDescription,
        setMetaDescription,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}
